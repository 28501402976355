import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useTable } from "react-table";
import "./App.css";
import ReactGA from 'react-ga4';

function App() {
  ReactGA.initialize('G-EDFMSEE6Q0');

  ReactGA.send({
    hitType: "PageView",
    page: "/",
    title: "Home",
  });

  const [text, setText] = useState("");
  const [jsonObject, setJsonObject] = useState(null);
  const [viewMode, setViewMode] = useState("text");

  const handlePaste = () => {
    navigator.clipboard.readText().then((clipText) => setText(clipText));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
  };

  const handleFormat = () => {
    try {
      const jsonObject = JSON.parse(text);
      setText(JSON.stringify(jsonObject, null, 2));
      setJsonObject(jsonObject);
    } catch (e) {
      alert("Invalid JSON. We can't format it.");
    }
  };

  const handleRemoveWhiteSpace = () => {
    setText(text.replace(/\s+/g, " ").trim());
  };

  const handleClear = () => {
    setText("");
    setJsonObject(null);
  };

  const handleLoadJson = async () => {
    const url = prompt("Please enter the JSON URL:");

    if (url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const jsonData = await response.json();
        setText(JSON.stringify(jsonData, null, 2));
        setJsonObject(jsonData);
        alert("JSON data successfully loaded and formatted.");
      } catch (e) {
        alert(`Failed to load JSON data from URL. Error: ${e.message}`);
      }
    }
  };

  const handleViewChange = (mode) => {
    setViewMode(mode);
  };

  // Table setup
  const data = React.useMemo(() => {
    if (!jsonObject) return [];
    return Object.keys(jsonObject).map((key) => ({
      name: key,
      value: JSON.stringify(jsonObject[key]),
    }));
  }, [jsonObject]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Value",
        accessor: "value",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="App">
      <header className="header">
        <img src="/json_logo.svg" alt="Logo" className="logo" />
        <nav className="menu">
          <a
            href="https://buymeacoffee.com/natheemyousuf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Donate
          </a>
          <a
            href="https://www.linkedin.com/in/natheemyousuf/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact
          </a>
          <a
            href="https://medium.com/tag/jsonview.xyz"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </a>
        </nav>
      </header>

      <div className="tabs">
        <button
          onClick={() => handleViewChange("viewer")}
          className={viewMode === "viewer" ? "active" : ""}
        >
          Viewer
        </button>
        <button
          onClick={() => handleViewChange("text")}
          className={viewMode === "text" ? "active" : ""}
        >
          Text
        </button>
      </div>

      {viewMode === "text" ? (
        <div>
          <div className="toolbar">
            <button onClick={handlePaste}>Paste</button>
            <button onClick={handleCopy}>Copy</button>
            <button onClick={handleFormat}>Format</button>
            <button onClick={handleRemoveWhiteSpace}>Remove white space</button>
            <button onClick={handleClear}>Clear</button>
            <button onClick={handleLoadJson}>Load JSON data</button>
          </div>
          <textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter your text here..."
          ></textarea>
        </div>
      ) : (
        <div className="viewer">
          {jsonObject ? (
            <>
              <ReactJson src={jsonObject} />
              <table {...getTableProps()} style={{ marginTop: "20px" }}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <div>No JSON data available</div>
          )}
        </div>
      )}

      <footer className="footer">
        Developed by - Natheem Yousuf
      </footer>
    </div>
  );
}

export default App;
